import { useState } from "react";
import axios from "axios";

export default function useRequest(path) {
    const [getRequestData, setGetRequest] = useState({
        responseData: [],
        isLoading: false,
        isError: "",
    });

    const [postRequestData, setPostRequest] = useState({
        responseData: [],
        isLoading: false,
        isError: "",
    });

    async function getData() {
        try {
            setGetRequest({ ...getRequestData, isLoading: true });

            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/${path}`);

            setGetRequest({ ...getRequestData, isLoading: false, responseData: data.data });
        } catch (error) {
            setGetRequest({ ...getRequestData, isLoading: false, isError: error.message });
        }
    }

    async function postData(body) {
        try {
            setPostRequest({ ...postRequestData, isLoading: true });

            const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/${path}`, body);

            setPostRequest({ ...postRequestData, isLoading: false, responseData: data.data });
        } catch (error) {
            setPostRequest({ ...postRequestData, isLoading: false, isError: error.message });
        }
    }

    return { getRequestData, getData, postRequestData, postData };
}
