import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";

import {
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Stack,
} from "@chakra-ui/react";
import { SearchIcon, ChevronDownIcon, ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";

import UserTable from "./components/UserTable";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import useLocalStorage from "hooks/useLocalStorage";
import useSearchValue from "hooks/useSearchValue";

function Users() {
    const router = useHistory();
    const [userData, setUserData] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [limitOnPage, setLimitOnPage] = useState(5);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showAll, setShowAll] = useState(false);
    const { setItem, getItem } = useLocalStorage("searchUserValue");
    const filteredUser = useSearchValue(userData, searchValue, page, limitOnPage, showAll);

    function openUserCard(id) {
        router.push(`/dashboard/user-card/${id}`);
    }

    function inputValue(e) {
        setSearchValue(e.target.value);
        setItem(e.target.value);
    }

    function allUsers(e) {
        if (e.target.textContent === "Все") {
            setShowAll(true);
            setLimitOnPage(e.target.textContent);
        } else {
            setShowAll(false);
            setLimitOnPage(e.target.textContent);
        }
    }

    async function getAllUsers() {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/accounts`);
            setUserData(res.data.data);

            const totalUsers = res.data.data.length;
            const totalPages = Math.ceil(totalUsers / limitOnPage);

            setTotalPages(totalPages);
        } catch (error) {
            console.error(error.message);
        }
    }

    useEffect(() => {
        getAllUsers();
    }, [limitOnPage, page]);

    useEffect(() => {
        setSearchValue(getItem() || "");
    }, []);

    return (
        <Flex direction='column' w='100%' pt={{ sm: "125px", md: "75px" }}>
            <Flex mb='25px'>
                <InputGroup>
                    <InputLeftElement pointerEvents='none' pl='10px' pt='5px'>
                        <SearchIcon color='gray.300' />
                    </InputLeftElement>

                    <Input
                        value={searchValue}
                        onChange={inputValue}
                        w='100%'
                        fontSize='sm'
                        ms='4px'
                        borderRadius='15px'
                        type='text'
                        placeholder='Поиск...'
                        mb='4px'
                        size='lg'
                    />
                </InputGroup>
            </Flex>

            <Flex mb='25px'>
                <Menu>
                    <MenuButton
                        w={{ base: "100%", md: "max-content" }}
                        as={Button}
                        rightIcon={<ChevronDownIcon />}
                    >
                        {limitOnPage}
                    </MenuButton>
                    <MenuList onClick={allUsers}>
                        <MenuItem>5</MenuItem>
                        <MenuItem>10</MenuItem>
                        <MenuItem>20</MenuItem>
                        <MenuItem>Все</MenuItem>
                    </MenuList>
                </Menu>
            </Flex>

            <Card mb='25px'>
                <CardBody>
                    <UserTable openUserCard={openUserCard} data={filteredUser} />
                </CardBody>
            </Card>

            {!showAll && (
                <Flex justify={{ sm: "center", md: "flex-start" }}>
                    <Stack direction={["column", "row"]} spacing='10px'>
                        <Button
                            leftIcon={<ChevronLeftIcon />}
                            onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 1))}
                            isDisabled={page === 1}
                        />
                        <Button>{page}</Button>
                        <Button
                            rightIcon={<ChevronRightIcon />}
                            onClick={() =>
                                setPage((prevPage) => Math.min(prevPage + 1, totalPages))
                            }
                            isDisabled={page === totalPages}
                        />
                    </Stack>
                </Flex>
            )}
        </Flex>
    );
}
export default Users;
