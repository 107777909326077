import { useParams } from "react-router-dom";

import { Flex, Text } from "@chakra-ui/react";

import UserComponents from "./UserComponent";

import useRequestArray from "hooks/useRequestArray";

function UserCard() {
    const { id } = useParams();
    const { responseData } = useRequestArray(`qr/userchecks/${id}`);

    return (
        <Flex direction='column' pt={{ sm: "125px", md: "75px" }}>
            <Flex
                direction={{ sm: "column", md: "row" }}
                justify='space-between'
                align='center'
                w='100%'
                mb='24px'
            >
                <Text fontSize='4xl' color='grey' fontWeight='bold'>
                    Чеки пользователя
                </Text>
            </Flex>
            <Flex>
                <UserComponents data={responseData} />
            </Flex>
        </Flex>
    );
}

export default UserCard;
