import { useState, useEffect } from "react";
import axios from "axios";

export default function useRequestObject(path) {
    const [getRequestData, setRequestData] = useState({
        responseData: {},
        isLoading: false,
        isError: "",
    });

    useEffect(() => {
        async function getData() {
            try {
                setRequestData({ ...getRequestData, isLoading: true });

                const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/${path}`);

                setRequestData({
                    ...getRequestData,
                    isLoading: false,
                    responseData: data,
                });
            } catch (error) {
                setRequestData({
                    ...getRequestData,
                    isLoading: false,
                    isError: error.message,
                });
            }
        }

        getData();
    }, [path]);

    return getRequestData;
}
