import axios from "axios";

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    useToast,
} from "@chakra-ui/react";

function ConfirmModal({ week }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    async function sendEmail() {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/notify/notify-all-winners`,
                { week }
            );

            onClose();
            toast({
                title: `Письма победителям периода: ${week} успешно отправлены`,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error(error.message);
            toast({
                title: `Ошибка при отправке писем`,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            onClose();
        }
    }

    return (
        <>
            <Button
                w={{ sm: "100%", md: "max-content" }}
                colorScheme='blue'
                isDisabled={week === "За все время" || week === "Выберите период" ? true : false}
                onClick={onOpen}
            >
                Отправить письма
            </Button>

            <Modal closeOnOverlayClick={false} isOpen={isOpen} isCentered onClose={onClose}>
                <ModalOverlay />
                <ModalContent mx='10px'>
                    <ModalHeader>Подтвердить отправку писем</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        Вы уверены что хотите отправить письма победителям?
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='green' mr={3} onClick={sendEmail}>
                            Да
                        </Button>
                        <Button colorScheme='red' onClick={onClose}>
                            нет
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default ConfirmModal;
