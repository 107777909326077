import { useMemo } from "react";

export default function useSearchValue(data, searchValue, page, limitOnPage, showAll) {
    const filteredData = useMemo(() => {
        const filteredData = data?.filter((item) => {
            const dataKey = Object.keys(item);

            return dataKey.some((key) =>
                String(item[key]).trim().toLowerCase().includes(searchValue.trim().toLowerCase())
            );
        });

        if (showAll) return filteredData;

        return filteredData.slice((page - 1) * limitOnPage, page * limitOnPage);
    }, [data, searchValue, page, limitOnPage, showAll]);

    return filteredData;
}
