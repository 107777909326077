import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";

import {
    Flex,
    Button,
    SimpleGrid,
    Heading,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import WinnersTable from "./components/WinnersTable";
import ConfirmModal from "./components/ConfirmModal";

import exportToExel from "helper/exportToExel";
import useRequest from "hooks/useRequest";

function WinnersBlock() {
    const router = useHistory();
    const [winnersData, setWinnersData] = useState([]);
    const [period, setPeriod] = useState([]);
    const [time, setTime] = useState("");
    const [week, setWeek] = useState("Выберите период");
    const { postRequestData, postData } = useRequest("toms-raffle/get-all-winners");

    function openUserCard(id) {
        router.push(`/dashboard/user-card/${id}`);
    }
    function openQrCard(id) {
        router.push(`/dashboard/check-card/${id}`);
    }

    function getPeriodWinners(e) {
        const target = e.target.textContent;
        setWeek(target);

        if (target !== "За все время") {
            const filterWinnersData = postRequestData.responseData.filter(
                (el) => el.raffle_type === target
            );
            setTime(filterWinnersData[0].raffle_period);
            setWinnersData(filterWinnersData);
        } else setWinnersData(postRequestData.responseData);
    }

    async function getPeriod() {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/toms-raffle/get-all-winners`,
                { hide: false }
            );

            const allRaffleType = response.data.data.map((el) => el.raffle_type);
            const uniqueRaffleTypes = [...new Set(allRaffleType)];

            setPeriod(uniqueRaffleTypes);
        } catch (error) {
            console.error(error.message);
        }
    }

    useEffect(() => {
        getPeriod();
        postData({ hide: false });
    }, []);

    return (
        <Flex
            direction='column'
            align={{ sm: "center", md: "flex-start" }}
            pt={{ sm: "125px", md: "75px" }}
        >
            <SimpleGrid w='100%' columns={{ sm: "1" }} gap='20px' mb='24px'>
                <Card>
                    <CardHeader justifyContent='center'>
                        <Heading>Выбор периода</Heading>
                    </CardHeader>

                    <Flex gap='20px' mt='20px' direction={{ sm: "column", md: "row" }}>
                        <CardBody>
                            <Menu>
                                <MenuButton
                                    w={{ sm: "100%", md: "max-content" }}
                                    as={Button}
                                    rightIcon={<ChevronDownIcon />}
                                >
                                    {week}
                                </MenuButton>

                                <MenuList onClick={getPeriodWinners}>
                                    <MenuItem>За все время</MenuItem>
                                    {period.map((el, index) => (
                                        <MenuItem key={index}>{el}</MenuItem>
                                    ))}
                                </MenuList>
                            </Menu>
                        </CardBody>

                        <ConfirmModal week={week} />
                    </Flex>
                </Card>

                {time != "" && <Text>Период проведения: {time}</Text>}
            </SimpleGrid>

            <Button
                w={{ sm: "100%", md: "max-content" }}
                fontSize={{ sm: "13px", md: "16px" }}
                mb='20px'
                onClick={() => exportToExel("Winners", postRequestData.responseData)}
            >
                Выгрузить победителей за все время
            </Button>

            <WinnersTable data={winnersData} openUserCard={openUserCard} openQrCard={openQrCard} />
        </Flex>
    );
}

export default WinnersBlock;
